import React ,{useState, useEffect} from 'react' 
import Stepper from './components/Stepper';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; 
import { useLocation, useNavigate } from 'react-router-dom'; 
import { API_URL, APP_NAME } from './Constants'; 
import Alert from './components/Alert';
import { Link } from 'react-router-dom';

const Ratehospitalservice = () => {

axios.defaults.baseURL = API_URL;
const [error, setError] = useState(null);
const location = useLocation();
const value = location.state?.value || localStorage.getItem('IPEncounter'); 
const [HospitalFBData, setHospitalFBData] = useState([]);
const [submitted, setSubmitted] = useState(false); 


  
  const GetHospitalFeedback = async () => {
    try {
        const response = await axios.get('/GetHospitalFeedback', {
        params: { Encounter: value }, 
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        const parsedData = JSON.parse(response.data.data);
        setHospitalFBData(Array.isArray(parsedData) ? parsedData : [parsedData]);
      } else {
        setError(response.data.errorMessage || 'Login failed, no data available.');
      }
    } catch (err) {
      console.error('API error:', err.response ? err.response.data : err.message);
      setError('An error occurred while fetching the data.');
    } 
  }
  

  useEffect(() => {
    GetHospitalFeedback();
  }, []);




const list = [
  <Example1 HospitalFBData={HospitalFBData} GetHospitalFeedback={GetHospitalFeedback} value={value}/>,
  <Example2 HospitalFBData={HospitalFBData} GetHospitalFeedback={GetHospitalFeedback} value={value}/>,
  <Example3 HospitalFBData={HospitalFBData} GetHospitalFeedback={GetHospitalFeedback} setSubmitted={setSubmitted} submitted={submitted} value={value}/>,  
]; // show loading if data is not yet available




  return (
    <>
      <div className="servicehead">
        {/* Add any additional styles or content here */}
      </div>
  
      <div className="App">
        {submitted ? (
          <>
           <div className="mt-5 message">
          <div className="thank-you-message bordersubmit pt-4 pb-4">
            <h2 className="text- successsubmit text-center">Successfully</h2>
            <h2 className="text- successsubmit text-center">Submitted your feedback response</h2>
            <h4 className="text-center mt-4">Thank you for your time and patience</h4>
            <h1 className="text-center mt-3">Have a good Day!!!</h1>
          </div>
  
          <div className="text-center mt-4">
            <h4 className="h5">
              Would you like to rate the 
              <Link to="/PatientExperience/Ratehospitalstaff" className="text- spannavy "> Rate Hospital Staff</Link>
            </h4>
          </div>
        </div>
          </>
        ) : (
          <>
            <h3>Rate Hospital Service</h3>
            <Stepper list={list} />
          </>
        )}
      </div>
    </>
  );
  
  


}

  const Example1 = ({ onPrev, onNext, HospitalFBData, GetHospitalFeedback, value }) => { 
    debugger;
    const initialData  = HospitalFBData[0]; 
    const [alert, setAlert] = useState({ message: '', type: '' });

    const handleAlertClose = () => {
      setAlert({ message: '', type: '' });
    };

    const [data, setData] = useState({ 
      N_HospitalStayNurseTreatCourtesyRespect: initialData ? initialData.N_HospitalStayNurseTreatCourtesyRespect : "",
      N_HospitalStayNurseListenCarefully: initialData ? initialData.N_HospitalStayNurseListenCarefully : "",
      N_HospitalStayNurseExplainWayUnderstand: initialData ? initialData.N_HospitalStayNurseExplainWayUnderstand : "",
      D_HospitalStayDoctorTreatCourtesyRespect: initialData ? initialData.D_HospitalStayDoctorTreatCourtesyRespect : "",
      D_HospitalStayDoctorListenCarefully: initialData ? initialData.D_HospitalStayDoctorListenCarefully : "",
      D_HospitalStayDoctorExplainWayUnderstand: initialData ? initialData.D_HospitalStayDoctorExplainWayUnderstand : "",
      BeforeGivingMedicineStaffTellWhatMedicineWasFor: initialData ? initialData.BeforeGivingMedicineStaffTellWhatMedicineWasFor : "",
      BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand: initialData ? initialData.BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand : "",
      N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital: initialData ? initialData.N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital : "",
      HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital: initialData ? initialData.HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital : "",
    });


    useEffect(() => {
      if (HospitalFBData.length > 0) {
        const updatedData = HospitalFBData[0];
        setData({
          N_HospitalStayNurseTreatCourtesyRespect: updatedData.N_HospitalStayNurseTreatCourtesyRespect || "",
          N_HospitalStayNurseListenCarefully: updatedData.N_HospitalStayNurseListenCarefully || "",
          N_HospitalStayNurseExplainWayUnderstand: updatedData.N_HospitalStayNurseExplainWayUnderstand || "",
          D_HospitalStayDoctorTreatCourtesyRespect: updatedData.D_HospitalStayDoctorTreatCourtesyRespect || "",
          D_HospitalStayDoctorListenCarefully: updatedData.D_HospitalStayDoctorListenCarefully || "",
          D_HospitalStayDoctorExplainWayUnderstand: updatedData.D_HospitalStayDoctorExplainWayUnderstand || "",
          BeforeGivingMedicineStaffTellWhatMedicineWasFor: updatedData.BeforeGivingMedicineStaffTellWhatMedicineWasFor || "",
          BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand: updatedData.BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand || "",
          N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital: updatedData.N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital || "",
          HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital: updatedData.HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital || "",
        });
      }
    }, [HospitalFBData]);


    const handleSaveClick = async (data) => {
      debugger;
      const requestData = {
        FK_Patient_Pk_Id: initialData?.FK_Patient_Pk_Id || "", 
        Encounter: value, 
        N_HospitalStayNurseTreatCourtesyRespect: data.N_HospitalStayNurseTreatCourtesyRespect,
        N_HospitalStayNurseListenCarefully: data.N_HospitalStayNurseListenCarefully,
        N_HospitalStayNurseExplainWayUnderstand: data.N_HospitalStayNurseExplainWayUnderstand,
        D_HospitalStayDoctorTreatCourtesyRespect: data.D_HospitalStayDoctorTreatCourtesyRespect,
        D_HospitalStayDoctorListenCarefully: data.D_HospitalStayDoctorListenCarefully,
        D_HospitalStayDoctorExplainWayUnderstand: data.D_HospitalStayDoctorExplainWayUnderstand,
        BeforeGivingMedicineStaffTellWhatMedicineWasFor: data.BeforeGivingMedicineStaffTellWhatMedicineWasFor,
        BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand: data.BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand,
        N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital: data.N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital,
        HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital: data.HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital,

        H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted: initialData?.H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted || "",
        H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted: initialData?.H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted || "",
        H_HospitalStayHowOftenRoomBathroomKeptClean: initialData?.H_HospitalStayHowOftenRoomBathroomKeptClean || "",
        H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight: initialData?.H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight || "",
        H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft: initialData?.H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft || "",
        H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth: initialData?.H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth || "",
        H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications: initialData?.H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications || "",
        H_WouldYouRecommendThisHospitalToFriendsFamily: initialData?.H_WouldYouRecommendThisHospitalToFriendsFamily || "",

        H_HospitalStayAnythingFeelWasOutstanding: initialData?.H_HospitalStayAnythingFeelWasOutstanding || "",
        H_WhatHaveDoneImproveHospitalStay: initialData?.H_WhatHaveDoneImproveHospitalStay || "",
        L_HaveLabTestsDrawingBloodDuringHospitalStay: initialData?.L_HaveLabTestsDrawingBloodDuringHospitalStay || "",
        L_HowWouldRateCourtesyAndRespectShownByLabStaffs: initialData?.L_HowWouldRateCourtesyAndRespectShownByLabStaffs || "",
        OverallHospitalRating: initialData?.OverallHospitalRating || "0",
      };
    
      try {
        const response = await axios.post(
            'SaveHospitalFeedback',
            requestData, 
            { headers: { 'Content-Type': 'application/json' } }
        );
    
        if (response.data.success) {
            setAlert({ message: 'Data saved successfully!', type: 'success' });
        } else {
            setAlert({ message: 'Something went wrong...', type: 'error' });
        }
    } catch (error) {
        console.error('Error saving row data:', error);
        setAlert({ message: 'Error saving data.', type: 'error' });
    }

    if (typeof GetHospitalFeedback === "function") {
      GetHospitalFeedback();
    } else {
      console.error("GetHospitalFeedback is not a function");
    }
    };


  return (
    <>

{alert.message && (
        <Alert message={alert.message} type={alert.type} onClose={handleAlertClose} />
      )}

<div className="card mt-5">
  <div className="card-header h5 bgcard">Communication with Nurse</div>
  <div className="card-body">
    <p className="card-text">During this hospital stay, how often did nurses treat you with courtesy and respect?</p>
<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="nCourtesy" value="never" 
        checked={data.N_HospitalStayNurseTreatCourtesyRespect === "never"} 
        onChange={() => setData({ ...data, N_HospitalStayNurseTreatCourtesyRespect: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="nCourtesy" className="form-check-input" value="sometimes" 
        checked={data.N_HospitalStayNurseTreatCourtesyRespect === "sometimes"} 
        onChange={() => setData({ ...data, N_HospitalStayNurseTreatCourtesyRespect: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="nCourtesy" className="form-check-input" value="always" 
        checked={data.N_HospitalStayNurseTreatCourtesyRespect === "always"} 
        onChange={() => setData({ ...data, N_HospitalStayNurseTreatCourtesyRespect: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

    <p className="card-text">During this hospital stay, how often did nurses listen carefully to you?</p>

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="nListen" value="never" 
        checked={data.N_HospitalStayNurseListenCarefully === "never"} 
        onChange={() => setData({ ...data, N_HospitalStayNurseListenCarefully: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="nListen" className="form-check-input" value="sometimes" 
        checked={data.N_HospitalStayNurseListenCarefully === "sometimes"} 
        onChange={() => setData({ ...data, N_HospitalStayNurseListenCarefully: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="nListen" className="form-check-input" value="always" 
        checked={data.N_HospitalStayNurseListenCarefully === "always"} 
        onChange={() => setData({ ...data, N_HospitalStayNurseListenCarefully: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>
    <p className="card-text">During this hospital stay, how often did nurses explain things in a way you could understand?</p>
  

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="nUnderstand" value="never" 
        checked={data.N_HospitalStayNurseExplainWayUnderstand === "never"} 
        onChange={() => setData({ ...data, N_HospitalStayNurseExplainWayUnderstand: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="nUnderstand" className="form-check-input" value="sometimes" 
        checked={data.N_HospitalStayNurseExplainWayUnderstand === "sometimes"} 
        onChange={() => setData({ ...data, N_HospitalStayNurseExplainWayUnderstand: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="nUnderstand" className="form-check-input" value="always" 
        checked={data.N_HospitalStayNurseExplainWayUnderstand === "always"} 
        onChange={() => setData({ ...data, N_HospitalStayNurseExplainWayUnderstand: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>
  </div>
</div>


    <div className="card mt-4">
      <div className="card-header h5 bgcard">Communication with Doctor</div>
      <div className="card-body">
        <p className="card-text">During this hospital stay, how often did doctors treat you with courtesy and respect?</p>
       

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="dRespect" value="never" 
        checked={data.D_HospitalStayDoctorTreatCourtesyRespect === "never"} 
        onChange={() => setData({ ...data, D_HospitalStayDoctorTreatCourtesyRespect: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="dRespect" className="form-check-input" value="sometimes" 
        checked={data.D_HospitalStayDoctorTreatCourtesyRespect === "sometimes"} 
        onChange={() => setData({ ...data, D_HospitalStayDoctorTreatCourtesyRespect: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="dRespect" className="form-check-input" value="always" 
        checked={data.D_HospitalStayDoctorTreatCourtesyRespect === "always"} 
        onChange={() => setData({ ...data, D_HospitalStayDoctorTreatCourtesyRespect: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

        <p className="card-text">During this hospital stay, how often did doctors listen carefully to you?</p>
   
<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="dListen" value="never" 
        checked={data.D_HospitalStayDoctorListenCarefully === "never"} 
        onChange={() => setData({ ...data, D_HospitalStayDoctorListenCarefully: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="dListen" className="form-check-input" value="sometimes" 
        checked={data.D_HospitalStayDoctorListenCarefully === "sometimes"} 
        onChange={() => setData({ ...data, D_HospitalStayDoctorListenCarefully: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="dListen" className="form-check-input" value="always" 
        checked={data.D_HospitalStayDoctorListenCarefully === "always"} 
        onChange={() => setData({ ...data, D_HospitalStayDoctorListenCarefully: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

        <p className="card-text">During this hospital stay, how often did doctors explain things in a way you could understand?</p>
      
<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="dUnderstand" value="never" 
        checked={data.D_HospitalStayDoctorExplainWayUnderstand === "never"} 
        onChange={() => setData({ ...data, D_HospitalStayDoctorExplainWayUnderstand: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="dUnderstand" className="form-check-input" value="sometimes" 
        checked={data.D_HospitalStayDoctorExplainWayUnderstand === "sometimes"} 
        onChange={() => setData({ ...data, D_HospitalStayDoctorExplainWayUnderstand: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="dUnderstand" className="form-check-input" value="always" 
        checked={data.D_HospitalStayDoctorExplainWayUnderstand === "always"} 
        onChange={() => setData({ ...data, D_HospitalStayDoctorExplainWayUnderstand: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>
      </div>
    </div>

    <div className="card mt-4">
      <div className="card-header h5 bgcard">Communication with medicine</div>
      <div className="card-body">
        <p className="card-text">Before giving you any new medicine, how often did hospital staff tell you what the medicine was for?</p>
       

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="Medicine" value="never" 
        checked={data.BeforeGivingMedicineStaffTellWhatMedicineWasFor === "never"} 
        onChange={() => setData({ ...data, BeforeGivingMedicineStaffTellWhatMedicineWasFor: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="Medicine" className="form-check-input" value="sometimes" 
        checked={data.BeforeGivingMedicineStaffTellWhatMedicineWasFor === "sometimes"} 
        onChange={() => setData({ ...data, BeforeGivingMedicineStaffTellWhatMedicineWasFor: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="Medicine" className="form-check-input" value="always" 
        checked={data.BeforeGivingMedicineStaffTellWhatMedicineWasFor === "always"} 
        onChange={() => setData({ ...data, BeforeGivingMedicineStaffTellWhatMedicineWasFor: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

        <p className="card-text">Before giving you any new medicine, how often did hospital staff describe possible side effects in a way you could understand?</p>

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="SideEffects" value="never" 
        checked={data.BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand === "never"} 
        onChange={() => setData({ ...data, BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="SideEffects" className="form-check-input" value="sometimes" 
        checked={data.BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand === "sometimes"} 
        onChange={() => setData({ ...data, BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="SideEffects" className="form-check-input" value="always" 
        checked={data.BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand === "always"} 
        onChange={() => setData({ ...data, BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>
      </div>
    </div>

    <div className="card mt-4">
      <div className="card-header h5 bgcard">Discharge Information</div>
      <div className="card-body">
        <p className="card-text">During this hospital stay, did doctors, nurses or other hospital staff talk with you about whether you would have the help you needed when you left the hospital?</p>
                
            <div className="form-check ">
              <label className='form-check-label mb-3'>
                <input type="radio" className="form-check-input" name="nHelp" value="Yes" 
                checked={data.N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital === "Yes"} 
                onChange={() => setData({ ...data, N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital: "Yes" })}/> Yes
              </label>
              <label className='form-check-label ms-5 '  id='inputno'>
                <input type="radio" name="nHelp" className="form-check-input" value="No" 
                checked={data.N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital === "No"} 
                onChange={() => setData({ ...data, N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital: "No" })}/> No
              </label>
             
            </div>

        <p className="card-text">During this hospital stay, did you get information in writing about symptoms or health problems to look out for after you left the hospital?</p>
           
           <div className="form-check">
              <label className='form-check-label mb-1'>
                <input type="radio" className="form-check-input" name="Symptoms" value="Yes" 
                checked={data.HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital === "Yes"} 
                onChange={() => setData({ ...data, HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital: "Yes" })}/> Yes
              </label>
              <label className='form-check-label ms-5 ' id='inputno'>
                <input type="radio" name="Symptoms" className="form-check-input " value="No" 
                checked={data.HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital === "No"} 
                onChange={() => setData({ ...data, HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital: "No" })}/> No
              </label>
             
            </div>

      
      </div>
    </div>    
    
<div className="d-flex justify-content-between btnbottom mt-4 ">        
      <button className='btn btn-secondary w-25 ms-4 stepbtn me-2' disabled onClick={onPrev}>Back</button>
     
        <button className='btn btn-secondary btnsubmit w-25 stepbtn me-2' onClick={() => handleSaveClick(data)}>Save</button>

        <button className='btn btn-primary btnsubmit w-25 me-4 stepbtn' onClick={() => { onNext(); handleSaveClick(data); }}>Next</button>
      </div>
    </>
  );
};


   const Example2 = ({ onPrev, onNext, HospitalFBData, GetHospitalFeedback, value }) => {  
    const initialData  = HospitalFBData[0]; 
    const [alert, setAlert] = useState({ message: '', type: '' });

    const handleAlertClose = () => {
      setAlert({ message: '', type: '' });
    };

    const [data, setData] = useState({ 
      H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted: initialData ? initialData.H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted : "",
      H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted: initialData ? initialData.H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted : "",
      H_HospitalStayHowOftenRoomBathroomKeptClean: initialData ? initialData.H_HospitalStayHowOftenRoomBathroomKeptClean : "",
      H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight: initialData ? initialData.H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight : "",
      H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft: initialData ? initialData.H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft : "",
      H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth: initialData ? initialData.H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth : "",
      H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications: initialData ? initialData.H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications : "",
      H_WouldYouRecommendThisHospitalToFriendsFamily: initialData ? initialData.H_WouldYouRecommendThisHospitalToFriendsFamily : ""
    });

    const handleSaveClick = async (data) => {
      debugger;
      const requestData = {
        // FK_Patient_Pk_Id: initialData.FK_Patient_Pk_Id,
        FK_Patient_Pk_Id: initialData?.FK_Patient_Pk_Id || "",
        Encounter: value, 
        N_HospitalStayNurseTreatCourtesyRespect: initialData?.N_HospitalStayNurseTreatCourtesyRespect || "",
        N_HospitalStayNurseListenCarefully: initialData?.N_HospitalStayNurseListenCarefully || "",
        N_HospitalStayNurseExplainWayUnderstand: initialData?.N_HospitalStayNurseExplainWayUnderstand || "",
        D_HospitalStayDoctorTreatCourtesyRespect: initialData?.D_HospitalStayDoctorTreatCourtesyRespect || "",
        D_HospitalStayDoctorListenCarefully: initialData?.D_HospitalStayDoctorListenCarefully || "",
        D_HospitalStayDoctorExplainWayUnderstand: initialData?.D_HospitalStayDoctorExplainWayUnderstand || "",
        BeforeGivingMedicineStaffTellWhatMedicineWasFor: initialData?.BeforeGivingMedicineStaffTellWhatMedicineWasFor || "",
        BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand: initialData?.BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand || "",
        N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital: initialData?.N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital || "",
        HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital: initialData?.HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital || "",

        H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted: data.H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted,
        H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted: data.H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted,
        H_HospitalStayHowOftenRoomBathroomKeptClean: data.H_HospitalStayHowOftenRoomBathroomKeptClean,
        H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight: data.H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight,
        H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft: data.H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft,
        H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth: data.H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth,
        H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications: data.H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications,
        H_WouldYouRecommendThisHospitalToFriendsFamily: data.H_WouldYouRecommendThisHospitalToFriendsFamily,

        H_HospitalStayAnythingFeelWasOutstanding: initialData?.H_HospitalStayAnythingFeelWasOutstanding || "",
        H_WhatHaveDoneImproveHospitalStay: initialData?.H_WhatHaveDoneImproveHospitalStay || "",
        L_HaveLabTestsDrawingBloodDuringHospitalStay: initialData?.L_HaveLabTestsDrawingBloodDuringHospitalStay || "",
        L_HowWouldRateCourtesyAndRespectShownByLabStaffs: initialData?.L_HowWouldRateCourtesyAndRespectShownByLabStaffs || "",
        OverallHospitalRating: initialData?.OverallHospitalRating || "0",
      };

    
      try {
        const response = await axios.post(
            'SaveHospitalFeedback',
            requestData, 
            { headers: { 'Content-Type': 'application/json' } }
        );
    
        if (response.data.success) {
            setAlert({ message: 'Data saved successfully!', type: 'success' });
        } else {
            setAlert({ message: 'Something went wrong...', type: 'error' });
        }
    } catch (error) {
        console.error('Error saving row data:', error);
        setAlert({ message: 'Error saving data.', type: 'error' });
    }

    if (typeof GetHospitalFeedback === "function") {
      GetHospitalFeedback();
    } else {
      console.error("GetHospitalFeedback is not a function");
    }


    };

  return (
    <>

{alert.message && (
        <Alert message={alert.message} type={alert.type} onClose={handleAlertClose} />
      )}

<div className="card mt-5">
      <div className="card-header h5 bgcard">Responsiveness of hospital staff</div>
      <div className="card-body">
        <p className="card-text">During this hospital stay, after you pressed the call button, how often did you get help as soon as you wanted it?</p>
      

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="hCallButton" value="never" 
        checked={data.H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted === "never"} 
        onChange={() => setData({ ...data, H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="hCallButton" className="form-check-input" value="sometimes" 
        checked={data.H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted === "sometimes"} 
        onChange={() => setData({ ...data, H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="hCallButton" className="form-check-input" value="always" 
        checked={data.H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted === "always"} 
        onChange={() => setData({ ...data, H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

        <p className="card-text">How often did you get help in getting to the bathroom or using a bedpan as soon as you wanted?</p>
      



    <div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="hHelp" value="never" 
        checked={data.H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted === "never"} 
        onChange={() => setData({ ...data, H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="hHelp" className="form-check-input" value="sometimes" 
        checked={data.H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted === "sometimes"} 
        onChange={() => setData({ ...data, H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="hHelp" className="form-check-input" value="always" 
        checked={data.H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted === "always"} 
        onChange={() => setData({ ...data, H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

</div>
</div>

<div className="card mt-4">
      <div className="card-header h5 bgcard">Cleanliness</div>
      <div className="card-body">
        <p className="card-text">During this hospital stay, how often were your room and bathroom kept clean?</p>
       

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="hClean" value="never" 
        checked={data.H_HospitalStayHowOftenRoomBathroomKeptClean === "never"} 
        onChange={() => setData({ ...data, H_HospitalStayHowOftenRoomBathroomKeptClean: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="hClean" className="form-check-input" value="sometimes" 
        checked={data.H_HospitalStayHowOftenRoomBathroomKeptClean === "sometimes"} 
        onChange={() => setData({ ...data, H_HospitalStayHowOftenRoomBathroomKeptClean: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="hClean" className="form-check-input" value="always" 
        checked={data.H_HospitalStayHowOftenRoomBathroomKeptClean === "always"} 
        onChange={() => setData({ ...data, H_HospitalStayHowOftenRoomBathroomKeptClean: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

</div>
</div>


<div className="card mt-4">
      <div className="card-header h5 bgcard">Quietness</div>
      <div className="card-body">
        <p className="card-text">During this hospital stay, how often was the area around your room quiet at night?</p>
       

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="hQuiet" value="never" 
        checked={data.H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight === "never"} 
        onChange={() => setData({ ...data, H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="hQuiet" className="form-check-input" value="sometimes" 
        checked={data.H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight === "sometimes"} 
        onChange={() => setData({ ...data, H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="hQuiet" className="form-check-input" value="always" 
        checked={data.H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight === "always"} 
        onChange={() => setData({ ...data, H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

    </div>
</div>


<div className="card mt-4">
      <div className="card-header h5 bgcard"> Transition of care </div>
      <div className="card-body">
        <p className="card-text">During this hospital stay, staff took my preferences and those of my family or caregiver into account in deciding what my health care needs would be when i left?</p>
   

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="hPreferences" value="never" 
        checked={data.H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft === "never"} 
        onChange={() => setData({ ...data, H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="hPreferences" className="form-check-input" value="sometimes" 
        checked={data.H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft === "sometimes"} 
        onChange={() => setData({ ...data, H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="hPreferences" className="form-check-input" value="always" 
        checked={data.H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft === "always"} 
        onChange={() => setData({ ...data, H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>


        <p className="card-text">When i left the hospital, I had a good understanding of the things I was responsible for in managing my health.</p>
       

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="hManagingMyHealth" value="never" 
        checked={data.H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth === "never"} 
        onChange={() => setData({ ...data, H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="hManagingMyHealth" className="form-check-input" value="sometimes" 
        checked={data.H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth === "sometimes"} 
        onChange={() => setData({ ...data, H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="hManagingMyHealth" className="form-check-input" value="always" 
        checked={data.H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth === "always"} 
        onChange={() => setData({ ...data, H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

        <p className="card-text">When I left hospital, I clearly understood the purpose for taking each of my medications</p>
        

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="hMedications" value="never" 
        checked={data.H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications === "never"} 
        onChange={() => setData({ ...data, H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="hMedications" className="form-check-input" value="sometimes" 
        checked={data.H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications === "sometimes"} 
        onChange={() => setData({ ...data, H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="hMedications" className="form-check-input" value="always" 
        checked={data.H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications === "always"} 
        onChange={() => setData({ ...data, H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

      </div>
    </div>


    <div className="card mt-4">
      <div className="card-header h5 bgcard">Willingness to recommend</div>
      <div className="card-body">
        <p className="card-text">Would you recommend this hospital to your friends and family?</p>
       

<div className="col-md-12 ps-2">
    <div className="row">
    <div className="form-check col-md-4 btnSpace">
      <label className='form-check-label'>
        <input type="radio" className="form-check-input" name="Recommend" value="never" 
        checked={data.H_WouldYouRecommendThisHospitalToFriendsFamily === "never"} 
        onChange={() => setData({ ...data, H_WouldYouRecommendThisHospitalToFriendsFamily: "never" })}/>Would you say never
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label'>
        <input type="radio" name="Recommend" className="form-check-input" value="sometimes" 
        checked={data.H_WouldYouRecommendThisHospitalToFriendsFamily === "sometimes"} 
        onChange={() => setData({ ...data, H_WouldYouRecommendThisHospitalToFriendsFamily: "sometimes" })}/> Sometimes
      </label></div>
      <div className="form-check col-md-4 btnSpace"><label className='form-check-label mb-3 '>
        <input type="radio" name="Recommend" className="form-check-input" value="always" 
        checked={data.H_WouldYouRecommendThisHospitalToFriendsFamily === "always"} 
        onChange={() => setData({ ...data, H_WouldYouRecommendThisHospitalToFriendsFamily: "always" })}/> Always
      </label>
    </div>
    </div>
    </div>

    </div>
    </div>      
 
      <div className="d-flex justify-content-between btnbottom mt-4">        
      <button className='btn btn-secondary w-25 ms-4 stepbtn me-2' onClick={onPrev}>Back</button>
     
        <button className='btn btn-secondary btnsubmit w-25 stepbtn me-2' onClick={() => handleSaveClick(data)}>Save</button>

        <button className='btn btn-primary btnsubmit w-25 me-4 stepbtn' onClick={() => { onNext(); handleSaveClick(data); }}>Next</button>
      </div>
    </>
  );
};

    const Example3 = ({ onPrev, onNext, HospitalFBData, GetHospitalFeedback, setSubmitted, submitted, value }) => {  
      debugger;
      const initialData  = HospitalFBData[0];  
      const [alert, setAlert] = useState({ message: '', type: '' });

      const handleAlertClose = () => {
        setAlert({ message: '', type: '' });
      };
  
      const handleSubmit = () => {
        setSubmitted(true); 
      };
  
      const [data, setData] = useState({ 
        H_HospitalStayAnythingFeelWasOutstanding: initialData ? initialData.H_HospitalStayAnythingFeelWasOutstanding : "",
        H_WhatHaveDoneImproveHospitalStay: initialData ? initialData.H_WhatHaveDoneImproveHospitalStay : "",
        L_HaveLabTestsDrawingBloodDuringHospitalStay: initialData ? initialData.L_HaveLabTestsDrawingBloodDuringHospitalStay : "",
        L_HowWouldRateCourtesyAndRespectShownByLabStaffs: initialData ? initialData.L_HowWouldRateCourtesyAndRespectShownByLabStaffs : "",
        OverallHospitalRating: initialData ? initialData.OverallHospitalRating : "0"                
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
          ...data,
          [name]: value, 
        });
      };
      

      const handleSaveClick = async (data) => {
        debugger;
        const requestData = {
          // FK_Patient_Pk_Id: initialData.FK_Patient_Pk_Id,
          FK_Patient_Pk_Id: initialData?.FK_Patient_Pk_Id || "", 
          Encounter: value, 
          N_HospitalStayNurseTreatCourtesyRespect: initialData?.N_HospitalStayNurseTreatCourtesyRespect || "",
          N_HospitalStayNurseListenCarefully: initialData?.N_HospitalStayNurseListenCarefully || "",
          N_HospitalStayNurseExplainWayUnderstand: initialData?.N_HospitalStayNurseExplainWayUnderstand || "",
          D_HospitalStayDoctorTreatCourtesyRespect: initialData?.D_HospitalStayDoctorTreatCourtesyRespect || "",
          D_HospitalStayDoctorListenCarefully: initialData?.D_HospitalStayDoctorListenCarefully || "",
          D_HospitalStayDoctorExplainWayUnderstand: initialData?.D_HospitalStayDoctorExplainWayUnderstand || "",
          BeforeGivingMedicineStaffTellWhatMedicineWasFor: initialData?.BeforeGivingMedicineStaffTellWhatMedicineWasFor || "",
          BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand: initialData?.BeforeGivingMedicineStaffDescribePossibleSideEffectsCouldUnderstand || "",
          N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital: initialData?.N_HospitalStayDoctNurseStaffTalkAboutHelpNeededWhenLeftHospital || "",
          HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital: initialData?.HospitalStayGetInformationAboutSymptomsOrHealthProblemLookAfterLeftHospital || "",
  
          H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted: initialData?.H_HospitalStayPressedCallButtonHowOftenGetHelpAsSoonAsYouWanted || "",
          H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted: initialData?.H_HowOftenGetHelpGettingBathroomOrBedpanAsSoonAsYouWanted || "",
          H_HospitalStayHowOftenRoomBathroomKeptClean: initialData?.H_HospitalStayHowOftenRoomBathroomKeptClean || "",
          H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight: initialData?.H_HospitalStayHowOftenAreaAroundYourRoomQuietAtNight || "",
          H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft: initialData?.H_HospitalStayStaffTookPreferencesMyFamilyIntoAcctDecidigMyHealthCareNeedWhenILeft || "",
          H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth: initialData?.H_WhenLeftHospitalHadGoodUnderstandingTheThingsResponsibleForManagingMyHealth || "",
          H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications: initialData?.H_WhenLeftHospitalClearlyUnderstoodPurposeForTakingMedications || "",
          H_WouldYouRecommendThisHospitalToFriendsFamily: initialData?.H_WouldYouRecommendThisHospitalToFriendsFamily || "",
  
          H_HospitalStayAnythingFeelWasOutstanding: data.H_HospitalStayAnythingFeelWasOutstanding,
          H_WhatHaveDoneImproveHospitalStay: data.H_WhatHaveDoneImproveHospitalStay,
          L_HaveLabTestsDrawingBloodDuringHospitalStay: data.L_HaveLabTestsDrawingBloodDuringHospitalStay,
          L_HowWouldRateCourtesyAndRespectShownByLabStaffs: data.L_HowWouldRateCourtesyAndRespectShownByLabStaffs,
          OverallHospitalRating: data.OverallHospitalRating,
        };
      
        try {
          const response = await axios.post(
              'SaveHospitalFeedback',
              requestData, 
              { headers: { 'Content-Type': 'application/json' } }
          );
      
          if (response.data.success) {
              setAlert({ message: 'Data saved successfully!', type: 'success' });
          } else {
              setAlert({ message: 'Something went wrong...', type: 'error' });
          }
      } catch (error) {
          console.error('Error saving row data:', error);
          setAlert({ message: 'Error saving data.', type: 'error' });
      }
      if (typeof GetHospitalFeedback === "function") {
        GetHospitalFeedback();
      } else {
        console.error("GetHospitalFeedback is not a function");
      }

      };

  return (
    <>

{alert.message && (
        <Alert message={alert.message} type={alert.type} onClose={handleAlertClose} />
      )}

<div className="card mt-5">
      <div className="card-header h5 bgcard">Additional voluntary questions</div>
      <div className="card-body">
        <p className="card-text">During this hospital stay, was there anything that you feel was outstanding?</p>
        <div className="form mb-3">
        <input autoComplete="off"
            type="text" id='H_HospitalStayAnythingFeelWasOutstanding' name='H_HospitalStayAnythingFeelWasOutstanding'
            value={data.H_HospitalStayAnythingFeelWasOutstanding} onChange={handleChange} className="form-control "
          />
      </div> 

        <p className="card-text">What could have been done to improve your hospital stay?</p>
        <div className="form">
        <input autoComplete="off"
            type="text" id='H_WhatHaveDoneImproveHospitalStay' name='H_WhatHaveDoneImproveHospitalStay'
            value={data.H_WhatHaveDoneImproveHospitalStay} onChange={handleChange} className="form-control "
          />
      </div>
    </div>
    </div>

    <div className="card mt-4">
      <div className="card-header h5 bgcard">Lab</div>
      <div className="card-body">
        <p className="card-text">Did you have any lab tests, such as drawing blood, during this hospital stay?</p>
        <div className="form-check ">
              <label className='form-check-label mb-3'>
                <input type="radio" className="form-check-input" name="Llabtest" value="Yes" 
                checked={data.L_HaveLabTestsDrawingBloodDuringHospitalStay === "Yes"} 
                onChange={() => setData({ ...data, L_HaveLabTestsDrawingBloodDuringHospitalStay: "Yes" })}/> Yes
              </label>
              <label className='form-check-label ms-5 '  id='inputno'>
                <input type="radio" name="Llabtest" className="form-check-input" value="No" 
                checked={data.L_HaveLabTestsDrawingBloodDuringHospitalStay === "No"} 
                onChange={() => setData({ ...data, L_HaveLabTestsDrawingBloodDuringHospitalStay: "No" })}/> No
              </label>
             
            </div>

            <p className="card-text">How would you rate the courtesy and respect shown toward you by the lab staff?</p>
  
<div className="form-group">
              <div className="row">
                <div className="col-lg-2 col-md-4 col-4 btnSpace">
                  <div className="form-check">
                    <label className="form-check-label">
                    <input type="radio" className="form-check-input" name="Llabrate" value="Excellent" 
                  checked={data.L_HowWouldRateCourtesyAndRespectShownByLabStaffs === "Excellent"} 
                  onChange={() => setData({ ...data, L_HowWouldRateCourtesyAndRespectShownByLabStaffs: "Excellent" })}/> Excellent
                   </label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-4 btnSpace">
                  <div className="form-check">
                    <label className="form-check-label">
                    <input type="radio" name="Llabrate" className="form-check-input" value="Very Good" 
                checked={data.L_HowWouldRateCourtesyAndRespectShownByLabStaffs === "Very Good"} 
                onChange={() => setData({ ...data, L_HowWouldRateCourtesyAndRespectShownByLabStaffs: "Very Good" })}/> Very Good
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-4 btnSpace">
                  <div className="form-check">
                    <label className="form-check-label">
                    <input type="radio" name="Llabrate" className="form-check-input" value="Good" 
              checked={data.L_HowWouldRateCourtesyAndRespectShownByLabStaffs === "Good"} 
              onChange={() => setData({ ...data, L_HowWouldRateCourtesyAndRespectShownByLabStaffs: "Good" })}/> Good
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-4 btnSpace">
                  <div className="form-check">
                    <label className="form-check-label">
                    <input type="radio" name="Llabrate" className="form-check-input" value="Fair" 
                checked={data.L_HowWouldRateCourtesyAndRespectShownByLabStaffs === "Fair"} 
                onChange={() => setData({ ...data, L_HowWouldRateCourtesyAndRespectShownByLabStaffs: "Fair" })}/> Fair
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-4 btnSpace">
                  <div className="form-check">
                    <label className="form-check-label">
                    <input type="radio" name="Llabrate" className="form-check-input" value="Poor" 
                checked={data.L_HowWouldRateCourtesyAndRespectShownByLabStaffs === "Poor"} 
                onChange={() => setData({ ...data, L_HowWouldRateCourtesyAndRespectShownByLabStaffs: "Poor" })}/> Poor
                    </label>
                  </div>
                </div>
              </div>
            </div>


    </div>
    </div>


    <div className="card mt-4">
      <div className="card-header h5 bgcard">Overall rating of the hospital</div>
      <div className="card-body">
        <p className="card-text ">We want know your overall rating of your stay at XYZ Hospital center. This is the stay that ended around 10/26/2024. Please do not include any othe stay in your answer.</p>
        <form className="flex-grow-1 ">
                        <div className='d-flex'> 
                               <div><p className='h6'>Rating</p></div> 
                               <div className=''> <span className="ms-5 ratingcount" >0-10</span></div> 
                         </div>
                         <div className='d-flex'>
                        <div>
                        <input  type="range" className="form-range " onChange={(e) => setData({ ...data, OverallHospitalRating: e.target.value })} 
                          min="0"  max="10"     style={{ width: '200px', cursor: 'pointer' }} 
                          id='OverallHospitalRating' name='OverallHospitalRating' value={data.OverallHospitalRating}
                        />
                         </div>
                         <div> 
                      <span className="ms-2 " >{data.OverallHospitalRating}</span> 
                      </div>
                         </div>
                    </form>

       <small><span className='smalltext '>Note:</span>using any number from 0 to 10, where 0 is the worst 
       hospital possible and 10 is the best hospital possible</small>
    </div>
    </div>     

      <div className="d-flex justify-content-between btnbottom mt-4">        
      <button className='btn btn-secondary w-25 ms-4 stepbtn me-2' onClick={onPrev}>Back</button>
        <button className='btn btn-secondary btnsubmit w-25 stepbtn me-2' onClick={() => handleSaveClick(data)}>Save</button>

        <button className='btn btn-primary btnsubmit w-25 me-4 stepbtn' onClick={() => { onNext(); handleSaveClick(data); handleSubmit(); }}>Submit</button>
      </div>
    </>
  );
};

 
export default Ratehospitalservice