import React from 'react';

const Logout = () => {
return (
    <>
      <div className="mt-5 message">
        <div className="thank-you-message bordersubmit pt-4 pb-4">
          <h2 className="text- successsubmit text-center">Successfully logged out</h2>
          <h4 className="text-center mt-4">Thank you for your time and patience</h4>
          <h1 className="text-center mt-3">Have a good Day!!!</h1>
        </div>
      </div>
    </>
  );
}

export default Logout