import React from 'react'

const Footer = () => {
  
  const currentYear = new Date().getFullYear();

  return (
    // <div className='footerContainer text-center'>   /* Anitha 11/07/24 */
    // <div className="footerContainer d-flex justify-content-center align-items-center "> //ANITHA 11112024
    <div className="footerContainer d-flex justify-content-center align-items-center p-3">
      {/* <p>© Copyright Showcase {currentYear}. All Rights Reserved      </p>   Anitha 11/07/24  */}
      <p className="mb-0">© Copyright Showcase {currentYear}. All Rights Reserved      </p> 
      </div>
  )
}

export default Footer