// // Geofence.js
// export function checkGeofence(userLat, userLon) {
//     const TARGET_LAT = 12.952039;  
//     const TARGET_LON = 80.200822;  
//     const GEOFENCE_RADIUS = 500; // 500 meters

//     function calculateDistance(lat1, lon1, lat2, lon2) {
//         const toRadians = (degrees) => degrees * (Math.PI / 180);
//         const R = 6371e3; // Radius of Earth in meters
//         const φ1 = toRadians(lat1);
//         const φ2 = toRadians(lat2);
//         const Δφ = toRadians(lat2 - lat1);
//         const Δλ = toRadians(lon2 - lon1);

//         const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
//                   Math.cos(φ1) * Math.cos(φ2) *
//                   Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
//         const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

//         return R * c; // Distance in meters
//     }

//     const distance = calculateDistance(userLat, userLon, TARGET_LAT, TARGET_LON);
//     return distance <= GEOFENCE_RADIUS ? "Within Geofence" : "Outside Geofence";
// }


export async function checkGeofence(userLat, userLon) {
    const TARGET_LAT = 12.952039;  
    const TARGET_LON = 80.200822;  
    const GEOFENCE_RADIUS = 500; // 500 meters

    function calculateDistance(lat1, lon1, lat2, lon2) {
        const toRadians = (degrees) => degrees * (Math.PI / 180);
        const R = 6371e3; // Radius of Earth in meters
        const φ1 = toRadians(lat1);
        const φ2 = toRadians(lat2);
        const Δφ = toRadians(lat2 - lat1);
        const Δλ = toRadians(lon2 - lon1);

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in meters
    }

    function getCurrentLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            lat: position.coords.latitude,
                            lon: position.coords.longitude,
                        });
                    },
                    (error) => {
                        reject("Failed to get location: " + error.message);
                    }
                );
            } else {
                reject("Geolocation is not supported by this browser.");
            }
        });
    }

    try {
        const currentPosition = await getCurrentLocation();
        const { lat: curUserLat, lon: curUserLon } = currentPosition; 
         //const distance = calculateDistance(userLat, userLon, curUserLat, curUserLon); 
        const distance = calculateDistance(userLat, userLon, TARGET_LAT, TARGET_LON); 
        return distance <= GEOFENCE_RADIUS ? "Within Geofence" : "Outside Geofence";
    } catch (error) {
        console.error(error);
        return "Error retrieving location";
    }
}

