import React from 'react';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import  Layout from './Layout';
import PatientExperience from './PatientExperience';
import Qrcode from './Qrcode';
import Ratehospitalstaff from './Ratehospitalstaff';
import Ratehospitalservice from './Ratehospitalservice';
//import TestRateHospitalService from './TestRateHospitalService';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import './components/Alert';
import Logout from './components/Logout';


function App() {
  return (
    <Router>
      <div>
        <Routes>
        
          <Route path="/" element={<Layout />} >
            <Route index element={<Qrcode />} />
            <Route path="PatientExperience" element={<PatientExperience />} />
            <Route path="PatientExperience/Ratehospitalstaff" element={<Ratehospitalstaff />} />
            <Route path="PatientExperience/Ratehospitalservice" element={<Ratehospitalservice />} />
            <Route path="PatientExperience/Logout" element={<Logout />} />
            {/* <Route path="PatientExperience/TestRateHospitalService" element={<TestRateHospitalService />} /> */}
        </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
