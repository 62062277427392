import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function PatientExperience() {
  const location = useLocation();
  const myValue = location.state?.myValue;  // Access the value here

  return (
    <>
      {/* <h3>Value from QR code: {myValue || "No value provided"}</h3> */}
      <div className="row align-items-center g-4 mt-1">
        <div className="col-md-3">
          <Link to="/PatientExperience/RateHospitalStaff" state={{ value: myValue }}>
            <button type="button" className="btn btn-primary w-100">Rate Hospital Staff</button>
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="/PatientExperience/Ratehospitalservice">
            <button type="button" className="btn btn-primary w-100">Rate Hospital Service</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default PatientExperience;