import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/showcase_logo.png';
import { IoMdHome } from "react-icons/io";
import Alert from './Alert';
import { GiPowerButton } from "react-icons/gi";

const showcaselogo = {  
  width:'180px',
  position: 'relative',
  paddingTop:'8px',
  paddingBottom:'8px',    
};

const Header = () => {

  const navigate = useNavigate(); 
  const location = useLocation();
  const [alert, setAlert] = useState({ message: '', type: '' });

  const handleHome = () => {
    navigate('/PatientExperience'); 
  };
  const handleAlertClose = () => {
    setAlert({ message: '', type: '' });
  };
  const handleLogout = () => {
    //localStorage.clear();
    navigate('/PatientExperience/Logout'); 
    //setAlert({ message: 'Logged out successfully...!', type: 'success' });
  };

  return (
    <header id="header" class="fixed-top">

     {alert.message && (
        <Alert message={alert.message} type={alert.type} onClose={handleAlertClose} />
      )}

    {/* <div class="HeaderContainer d-flex align-items-center">      */}
    <div class="HeaderContainer align-items-center">  
    {/* <Link to = "/"> */}

    {/* <Link to = "/PatientExperience">
    <img src={logo}  alt="ShowCase Logo" class="img-fluid"  style={showcaselogo} />
    </Link> */}

     {location.pathname !== '/' && location.pathname !== '/PatientExperience/Logout' ? (
        <Link to = "/PatientExperience"><img src={logo}  alt="ShowCase Logo" class="img-fluid"  style={showcaselogo} /></Link>
      ) : (
        <img src={logo}  alt="ShowCase Logo" class="img-fluid"  style={showcaselogo} />
      )}

    <div style={{ float: 'right', paddingTop: '12px', paddingBottom: '12px', color: '#4267B2' }}>
    {location.pathname !== '/' && location.pathname !== '/PatientExperience' && location.pathname !== '/PatientExperience/Logout' ? (
        <IoMdHome size={22} onClick={handleHome}/>
      ) : null} &nbsp;&nbsp;&nbsp;
      {location.pathname !== '/' && location.pathname === '/PatientExperience' ? (
        <GiPowerButton size={22} onClick={handleLogout}/>
      ) : null}
      </div>
      </div>
      </header>
  )
}

export default Header