import React, { useState, useEffect } from 'react';
import PersonIcon from "../assets/person-circle.svg";

function NurseRating({ nurseData, setArrFunc }) {
  const [ratings, setRatings] = useState(
    nurseData.reduce((acc, user) => ({ ...acc, [user.Visitng_Pk_Id]: Math.round(user.RatingsVar || 0) }), {})
  );

  const [comments, setComments] = useState(
    nurseData.reduce((acc, user) => ({ ...acc, [user.Visitng_Pk_Id]: user.Comments || "" }), {})
  );

  const [editMode, setEditMode] = useState(
    nurseData.reduce((acc, user) => ({ ...acc, [user.Visitng_Pk_Id]: Boolean(user.Comments) }), {})
  );


useEffect(() => {
  const user = nurseData[0]; // Access each user in nurseData, assuming a single nurse at a time.
  const nurseRatingData = {
    Visitng_Pk_Id: user.Visitng_Pk_Id,
    Rating: ratings[user.Visitng_Pk_Id],
    Comment: comments[user.Visitng_Pk_Id]
  };

  setArrFunc(prevArray => {
    // Ensure prevArray is an array
    const arrayToUse = Array.isArray(prevArray) ? prevArray : [];

    // Check if an entry for this UserID already exists
    const existingEntryIndex = arrayToUse.findIndex(entry => entry.Visitng_Pk_Id === user.Visitng_Pk_Id);

    if (existingEntryIndex !== -1) {
      // Update the existing entry
      const updatedArray = [...arrayToUse];
      updatedArray[existingEntryIndex] = nurseRatingData;
      return updatedArray;
    } else {
      // Add a new entry
      return [...arrayToUse, nurseRatingData];
    }
  });
}, [ratings, comments, nurseData]);


  const handleStarClick = (Visitng_Pk_Id, rating) => {
    //setArrFunc(nurseData);
    setRatings((prevRatings) => ({
      ...prevRatings,
      [Visitng_Pk_Id]: rating,
    }));
  };

  const handleCommentClick = (Visitng_Pk_Id) => {
    //setArrFunc(nurseData);
    setEditMode((prevMode) => ({
      ...prevMode,
      [Visitng_Pk_Id]: true,
    }));
  };

  const handleCommentChange = (Visitng_Pk_Id, newComment) => {
    //setArrFunc(nurseData);
    setComments((prevComments) => ({
      ...prevComments,
      [Visitng_Pk_Id]: newComment,
    }));
  };

  const renderStars = (rating, Visitng_Pk_Id) => {
    return (
      <div className="d-flex" style={{ marginBottom: '5px' }}>
        {Array.from({ length: 5 }, (_, i) => (
          <i
            key={i + 1}
            className={`bi ${i + 1 <= rating ? 'bi-star-fill text-warning' : 'bi-star text-secondary'}`}
            onClick={() => handleStarClick(Visitng_Pk_Id, i + 1)}
            style={{ cursor: 'pointer', fontSize: '1.3em', margin: '0 5px' }} // Increased size and margin
          ></i>
        ))}
      </div>
    );
  };

  return (
    

<div className=''>
<div className="row ">
  {nurseData.slice(0, 3).map((user) => (
    <div key={user.Visitng_Pk_Id} className="col-md-12  p-3 ">
      
    {/* Left side: Person Icon, Name, and Star Rating */}
    <div className="row me-4 ">
      {/* Person Icon */}
      <div className='col-md-8 col-sm-2 d-flex '>
      <img src={PersonIcon} alt="Person Icon" className="staffIcon me-2" style={{ width: '40px', height: '40px' }} />
    
      {/* Name */}
      <p className="p-1 roboto-bold mb-0" style={{ fontSize: '1em', whiteSpace: 'nowrap' }}>
        {user.VisitBy}
      </p>  </div>
      {/* Star Rating */}
      <div className="col-md-4 col-sm-2 starwrap" style={{marginLeft:'-5%'}} >
        {renderStars(ratings[user.Visitng_Pk_Id], user.Visitng_Pk_Id)}
      </div>
    </div>

    {/* Right side: Comments */}
    <div className="col-12 col-md-12 text-start mt-2 commentbox1">
      {editMode[user.Visitng_Pk_Id] ? (
        <textarea
          value={comments[user.Visitng_Pk_Id]}
          onChange={(e) => handleCommentChange(user.Visitng_Pk_Id, e.target.value)}
          className="form-control"
          rows={2}
          style={{ resize: 'none', width: '100%' }}
        />
      ) : (
        <span 
          onClick={() => handleCommentClick(user.Visitng_Pk_Id)} 
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: '5px' }}
        >
          {comments[user.Visitng_Pk_Id] || 'Comments'}
        </span>
      )}
    </div>
  </div>
  ))}
</div>
</div>



  );
}

export default NurseRating;
