import React, { useState } from "react";

const Stepper = ({ list }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const stepsCount = list.length;

  const steps = list.map((_, i) => (
    <div
      onClick={() => setCurrentStep(i)}
      className={`steps ${currentStep >= i ? "active" : ""}`}
      key={i}
    >
      {i + 1}
    </div>
  ));

  const onPrev = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const onSave = () => {
    alert("Saved");
  };

  const onNext = () => {
    if (currentStep !== stepsCount - 1) {
      setCurrentStep(currentStep + 1);
      window.scrollTo(0, 0);
    }
  };

  return (
    <section className="stepper ">
      <div className="steps-container w-50">
      <div className="steps-wrapper">
  {steps}
  {/* Arrow before and after Step 2 */}
  {stepsCount > 2 && (
    <>
      <span className={`arrow ${currentStep >= 1 ? "active" : ""} before-step-2`}>&#x27A4;</span>
      <span className={`arrow ${currentStep >= 2 ? "active" : ""} after-step-2 ms-4 arrowstep2`}>&#x27A4;</span>
    </>
  )}
  {stepsCount < 2 && (
    <span className={`arrow ${currentStep <= 1 ? "active" : ""} before-step-2 ms- arrowstep2`}>&#x27A4;</span>
  )}
</div>

        <div className="progress-container w-100">
          <div
            className="progress-line w-100"
            style={{ width: `${(100 / (stepsCount - 1)) * currentStep}%`, marginTop:"-36px" }}
          ></div>
        </div>
      </div>
      <div>{React.cloneElement(list[currentStep], { onPrev, onSave, onNext })}</div>
    </section>
  );
};

export default Stepper;
