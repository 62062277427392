import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer'



const Layout = () => {
  return (
    //<div> AA - 11152024
    <div className="d-flex flex-column min-vh-100">
        <Header />
        {/* <div className='bodyContainer roboto-regular'>     AA - 11152024       */}
        <div className='bodyContainer roboto-regular flex-grow-1'> 
           <Outlet />
        </div>
        <Footer />
    </div>
  )
}

export default Layout