// // Qrcode.js
// //ENTER EOCID
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import barcodeScanner from './assets/barcode_scanner.png';
// import Alert from './components/Alert';

// function Qrcode() {
//   const [inputValue, setInputValue] = useState('');
//   const [isClicked, setIsClicked] = useState(false);
//   const [isInputValue, setIsInputValue] = useState(false);
//   const [alert, setAlert] = useState({ message: '', type: '' });

//   const handleAlertClose = () => {
//     setAlert({ message: '', type: '' });
//   };

//   const handleImageClick = () => {
//     if (!isClicked) {
//       if (inputValue === '') {
//         // Show the custom alert message
//         setAlert({ message: 'Please enter valid visit ID', type: 'error' });
//         setIsInputValue(false);
//         return;
//       } else {
//         localStorage.setItem('IPEncounter', inputValue)
//         setIsInputValue(true);
//       }
//     }
//     setIsClicked(true);
//   };

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   return (
//     <div>
//       <h2>Scan your armband barcode</h2>
//       <input
//         type="text"
//         value={inputValue}
//         onChange={handleInputChange}
//         placeholder="Enter visit ID"
//       />

      
//       {alert.message && (
//         <Alert message={alert.message} type={alert.type} onClose={handleAlertClose} />
//       )}

     
//       {isInputValue ? (
//         <Link to="/PatientExperience" state={{ myValue: inputValue }}>
//           <img
//             src={barcodeScanner}
//             alt="Barcode Scanner"
//             onClick={handleImageClick}
//             className="img-fluid"
//           />
//         </Link>
//       ) : (
//         <img
//           src={barcodeScanner}
//           alt="Barcode Scanner"
//           onClick={handleImageClick}
//           className="img-fluid"
//         />
//       )}
//     </div>
//   );
// }

// export default Qrcode;


//SCAN CODE
// import { useState, useEffect } from 'react';
// import BarcodeScannerComponent from 'react-qr-barcode-scanner';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';  // Import useNavigate
// import { API_URL } from './Constants';
// import { checkGeofence } from './Geofence'; // Ensure the path is correct
// import Alert from '@mui/material/Alert';

// axios.defaults.baseURL = API_URL;

// const Qrcode = () => {
//   const [isScanning, setIsScanning] = useState(true);
//   const [data, setData] = useState('');
//   const [Apiresponsedata, setresponsedata] = useState([]);
//   const navigate = useNavigate();  // Initialize useNavigate

//   const handleScan = (error, result) => {
//     if (result) {
//       setData(result.text);
//       setIsScanning(false);
//       localStorage.setItem('IPEncounter', result.text)
//       callApi(result.text);
//     }
//     if (error) {
//       console.error(error);
//     }
//   };

//   const callApi = async (scannedData) => {
//     try {
//       const response = await axios.get('/GetEocDetails', {
//         params: { EocNumber: scannedData },
//         headers: { 'Content-Type': 'application/json' },
//       });
//       if (response.data.success) {
//         const parsedData = JSON.parse(response.data.data);
//         setresponsedata(Array.isArray(parsedData) ? parsedData : [parsedData]);
//       }
//     } catch (error) {
//       console.error('API call failed:', error);
//     }
//   };

//   useEffect(() => {
//     if (!isScanning) {
//       const timer = setTimeout(() => setIsScanning(true), 5000);
//       return () => clearTimeout(timer);
//     }
//   }, [isScanning]);

//   // Function to check the geofence status and navigate if within radius
//   const [alertMessage, setAlertMessage] = useState("");
//   const handleGeofenceCheck = (latitude, longitude) => {
//     //alert("1");
//     const geofenceStatus = checkGeofence(latitude, longitude);
//     if (geofenceStatus === "Within Geofence") {
//       navigate('/PatientExperience');  // Navigate to PatientExperience.js
//       return true;
//     }
//     else{
//       return false;
//     }
//   };
//   useEffect(() => {
//     if (Apiresponsedata.length > 0) {
//       const result = Apiresponsedata.some((item) => {
//         return handleGeofenceCheck(item.HospitalLatitude, item.HospitalLongitude);
//       });

//       if (!result) {
//         setAlertMessage("You are not within a hospital location.");
//         setTimeout(() => {
//           setAlertMessage("");
//         }, 5000); // Hide alert after 10 seconds
//       }
//     }
//   }, [Apiresponsedata, navigate]);
//   // const handleGeofenceCheck = (latitude, longitude) => {
//   //   //alert("1");
//   //   const geofenceStatus = checkGeofence(latitude, longitude);
//   //   if (geofenceStatus === "Within Geofencee") {
//   //     navigate('/PatientExperience');  // Navigate to PatientExperience.js
//   //   }
//   //   else{
//   //     //not in a hospital location
//   //   }
//   // };

//   const [dimensions, setDimensions] = useState({ width: 500, height: 500 });
//   useEffect(() => {
//     const updateDimensions = () => {
//       const isMobile = window.innerWidth <= 768; // Define a breakpoint for mobile
//       setDimensions({
//         width: isMobile ? window.innerWidth * 0.7 : 500, // 80% of screen width for mobile
//         height: isMobile ? window.innerWidth * 0.7 : 500, // Maintain square aspect ratio
//       });
//     };

//     updateDimensions(); // Set initial dimensions
//     window.addEventListener("resize", updateDimensions); // Update on resize

//     return () => window.removeEventListener("resize", updateDimensions);
//   }, []);

//   return (
//     <div style={{ textAlign: 'center', marginTop: '20px' }}>
//       <h2>Barcode/QR Code Scanner</h2>
//       {isScanning ? (
//         // <BarcodeScannerComponent width={500} height={500} onUpdate={handleScan} />
//         <BarcodeScannerComponent width={dimensions.width} height={dimensions.height} onUpdate={handleScan} />
//       ) : (
//         // <p>Scan complete! Loading...</p>
//         null
//       )}
//       {/* <p>Scanned Data: {data}</p> */}

//       {/* {Apiresponsedata.map((item, index) => {
//             const geofenceStatus = checkGeofence(
//               item.HospitalLatitude,
//               item.HospitalLongitude
//             );

//             // Call geofence check and navigate if within the radius
//            handleGeofenceCheck(item.HospitalLatitude, item.HospitalLongitude);
//           })} */}

//       {/* Display API response data with geofence check */}
//       {/* {Apiresponsedata.length > 0 && (
//         <div>
//           <h3>API Response Data:</h3>
//           {Apiresponsedata.map((item, index) => {
//             const geofenceStatus = checkGeofence(
//               item.HospitalLatitude,
//               item.HospitalLongitude
//             );

//             // Call geofence check and navigate if within the radius
//            handleGeofenceCheck(item.HospitalLatitude, item.HospitalLongitude);

//             return (
//               <div key={index}>
//                 <p>EOC Number: {item.EocNumber}</p>
//                 <p>First Name: {item.FirstName}</p>
//                 <p>Last Name: {item.LastName}</p>
//                 <p>Facility Name: {item.FacilityName}</p>
//                 <p>Hospital Longitude: {item.HospitalLongitude}</p>
//                 <p>Hospital Latitude: {item.HospitalLatitude}</p>
//                 <p>Geofence Status: {geofenceStatus}</p>
//                 <hr />
//               </div>
//             );
//           })}
//         </div>
//       )} */}

// {alertMessage && (
//   <div
//     style={{
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       position: 'fixed', // Make it appear in the center
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100vh',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Dim the background
//       zIndex: 1000, // Ensure it appears above other content
//     }}
//   >
//     <Alert
//       severity="info"
//       onClose={() => setAlertMessage("")}
//       style={{
//         width: '400px', // Set the width
//         textAlign: 'center', // Center the text
//       }}
//     >
//       {alertMessage}
//     </Alert>
//   </div>
// )}

//     </div>
//   );
// };

// export default Qrcode;


////SCAN CODE 1
import React, { useState, useEffect } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { checkGeofence } from "./Geofence"; // Ensure the path is correct
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

const Qrcode = () => {
  const [isScanning, setIsScanning] = useState(true);
  const [data, setData] = useState("");
  const [Apiresponsedata, setResponsedata] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State to handle loading spinner
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const resetComponent = () => {
    // Explicitly reset all states
    setIsScanning(true);
    setData("");
    setResponsedata([]);
    setIsLoading(false);
    setAlertMessage("");
  };

  const handleScan = (error, result) => {
    if (result) {
      setIsLoading(true); // Start loading spinner
      setData(result.text);
      setIsScanning(false);
      localStorage.setItem("IPEncounter", result.text);
      callApi(result.text);
    }
    if (error) {
      console.error(error);
    }
  };

  const callApi = async (scannedData) => {
    try {
      const response = await axios.get("/GetEocDetails", {
        params: { EocNumber: scannedData },
        headers: { "Content-Type": "application/json" },
      });
      if (response.data.success) {
        const parsedData = JSON.parse(response.data.data);
        setResponsedata(Array.isArray(parsedData) ? parsedData : [parsedData]);
      }
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false); // Stop loading spinner after API call
    }
  };

  const handleGeofenceCheck = async (latitude, longitude) => {
    try {
      const geofenceStatus = await checkGeofence(latitude, longitude);
      if (geofenceStatus === "Within Geofence") {
        navigate("/PatientExperience"); // Navigate to PatientExperience.js
        return true;
      } else {
        setAlertMessage("You are not within the hospital location.");
        setTimeout(() => {
          setAlertMessage(""); // Clear the alert message
          resetComponent(); // Reset the component explicitly
        }, 3000); // Wait 3 seconds before resetting
        return false;
      }
    } catch (error) {
      console.error("Error checking geofence:", error);
      setAlertMessage("An error occurred while checking the geofence.");
      setTimeout(() => {
        setAlertMessage(""); // Clear the alert message
        resetComponent(); // Reset the component explicitly
      }, 3000); // Wait 3 seconds before resetting
      return false;
    }
  };

    const [dimensions, setDimensions] = useState({ width: 500, height: 500 });
  useEffect(() => {
    const updateDimensions = () => {
      const isMobile = window.innerWidth <= 768; // Define a breakpoint for mobile
      setDimensions({
        width: isMobile ? window.innerWidth * 0.7 : 500, // 80% of screen width for mobile
        height: isMobile ? window.innerWidth * 0.7 : 500, // Maintain square aspect ratio
      });
    };

    updateDimensions(); // Set initial dimensions
    window.addEventListener("resize", updateDimensions); // Update on resize

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (Apiresponsedata.length > 0) {
      const checkGeofences = async () => {
        const result = await Promise.all(
          Apiresponsedata.map((item) =>
            handleGeofenceCheck(item.HospitalLatitude, item.HospitalLongitude)
          )
        );

        if (!result.includes(true)) {
          setTimeout(() => {
            setAlertMessage(""); // Clear the alert message
            resetComponent(); // Explicitly reset the component
          }, 3000); // Wait 3 seconds before resetting
        }
      };

      checkGeofences();
    }
  }, [Apiresponsedata]);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h2>Barcode/QR Code Scanner</h2>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress size={60} /> {/* Material-UI spinner */}
        </div>
      ) : (
        isScanning && (
          // <BarcodeScannerComponent
          //   width={500}
          //   height={500}
          //   onUpdate={(error, result) => {
          //     handleScan(error, result);
          //     if (error) {
          //       console.error("Scan error:", error);
          //     }
          //   }}
          // />
          <BarcodeScannerComponent
          width={dimensions.width}
          height={dimensions.height}
            onUpdate={(error, result) => {
              handleScan(error, result);
              if (error) {
                console.error("Scan error:", error);
              }
            }}
          />
        )
      )}

      {alertMessage && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed", // Make it appear in the center
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim the background
            zIndex: 1000, // Ensure it appears above other content
          }}
        >
          <Alert
            severity="info"
            onClose={() => setAlertMessage("")}
            style={{
              width: "400px", // Set the width
              textAlign: "center", // Center the text
            }}
          >
            {alertMessage}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Qrcode;

 