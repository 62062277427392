// Alert.js
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function Alert({ message, type = 'info', onClose }) {
  useEffect(() => {
    // Automatically close the alert after 10 seconds
    const timer = setTimeout(() => {
      onClose();
    }, 10000);

    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, [onClose]);

  if (!message) return null;

  const getAlertClass = () => {
    switch (type) {
      case 'success':
        return 'alert alert-success';
      case 'error':
        return 'alert alert-danger';
      case 'warning':
        return 'alert alert-warning';
      default:
        return 'alert alert-info';
    }
  };

  return (
    <div
      className={`${getAlertClass()} alert-top-right`}
      style={{
        position: 'fixed',
        top: '72px',
        right: '20px',
        zIndex: 1000,
        minWidth: '200px',
        padding: '10px',
        borderRadius: '4px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <span>{message}</span>
      <button onClick={onClose} className="btn-close" style={{ marginLeft: '10px' }}></button>
    </div>
  );
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  onClose: PropTypes.func.isRequired,
};

export default Alert;
